import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import "element-ui/lib/theme-chalk/index.css";
// 按需引入Element
import "./plugins/element.js";
// 引入DataV
import dataV from "@jiaminghi/data-view";
// 引入全局公共样式
import "./assets/Sass/ComminSass.scss";
// 引入全局公用方法
import Commonmethods from "@/utils/Commonmethods";
// fade/zoom 等
import "element-ui/lib/theme-chalk/base.css";
// collapse 展开折叠
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
// vueQr https://blog.csdn.net/weixin_42601136/article/details/114839489 https://blog.csdn.net/qq826303461/article/details/117321812
import vueQr from "vue-qr";
import VueClipboard from "vue-clipboard2";
import CommonSelection from "./components/select.vue";
import SearchTree from './components/searchTree.vue'

import "element-ui/lib/theme-chalk/index.css";

import "vue-cron-generator/src/styles/global.css";
import Element from "element-ui";
import i18n from "./i18n/index";

// import "@/assets/iconfont/index.js";

// 使用iconfont的fontClass
import "@/assets/iconfont/iconfont.css"
// 使用iconfont的symbol
import "@/assets/iconfont/iconfont.js"

// 引入svg图标
import SvgIcon from "./components/SvgIcon.vue";

Vue.use(Element, {
  size: localStorage.getItem("size") || "small", // set element-ui default size
  i18n: (key, value) => {
    return i18n.t(key, value);
  },
});

Vue.use(VueClipboard);
Vue.use(CommonSelection);
Vue.use(SearchTree)
Vue.component("CommonSelection", CommonSelection);
Vue.component("SearchTree", SearchTree);
// 关闭生产提示
Vue.config.productionTip = false;
// 使用DataV的组件
Vue.use(dataV);
// 使用vueQr
Vue.use(vueQr);
Vue.component(CollapseTransition.name, CollapseTransition);
Vue.component("SvgIcon", SvgIcon);
Vue.prototype.$Commonmethods = Commonmethods;

import webScoket from "@/utils/webScoket";
Vue.prototype.$webScoket = webScoket;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
